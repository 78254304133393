<template>
    <div class="interactive-manage">
        <div class="header">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
                <el-breadcrumb-item class="first">任务中心</el-breadcrumb-item>
                <el-breadcrumb-item class="second">任务列表</el-breadcrumb-item>
                <el-breadcrumb-item class="third">任务详情</el-breadcrumb-item>
                <el-breadcrumb-item class="fourth">学生提交任务</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button type="primary" class="blue-btn" @click="goBack">返回</el-button>
        </div>
        <div class="interactive-content">
            <div class="content-top">
                <span class="student-name">{{student_name}}</span>
                <span class="class-name">{{class_name}}</span>
                <i v-if="task_works_state === 1" class="iconfont is-qualified">&#xe692;</i>
                <i v-if="task_works_state === 2" class="iconfont no-qualified">&#xe690;</i>
                <span class="task-works_-tate" v-if="task_works_state === '0'">暂未审批</span>
            </div>
            <div class="content-details" v-if="studentContent">
                <span>内容</span>
                <span>{{studentContent}}</span>
            </div>
            <div class="content-module">
                <div class="module-script module-top" v-if="scriptList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">脚本</div>
                    </div>
                    <div class="module-content">
                        <div class="script-item" v-for="item in scriptList" @click="viewScriptDetail(item)">
                            <div class="item-cover">
                                <img :src="item.content.cover_url" alt="">
                            </div>
                            <div class="annex-info">
                                <span class="title text-overflow">{{item.content.name}}</span>
                                <div class="bottom">
                                    <span class="class-name">{{item.content.classification_name}}</span>
                                    <span class="time">{{item.content.create}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="script-item" style="height: 0;padding: 0;margin: 0"></div>
                        <div class="script-item" style="height: 0;padding: 0;margin: 0"></div>
                    </div>
                </div>
                <div class="module-teletext module-top" v-if="graphicList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">图文</div>
                    </div>
                    <div class="module-content">
                        <div class="teletext-item" v-for="item in graphicList">
                            <div class="item-cover">
                                <img src="../../../assets/img/image/file-type-new.png" alt="">
                            </div>
                            <a class="title" :href="item.content.url" target="_blank">{{item.content.name}}</a>
                        </div>
                    </div>
                </div>
                <div class="module-video module-top" v-if="videoList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">视频</div>
                    </div>
                    <div class="module-content">
                        <div class="video-item" v-for="item in videoList">
                            <div class="item-cover">
                                <video :src="item.content.video_file.url" controls="controls"></video>
                            </div>
                            <span class="title">{{item.content.title}}</span>
                        </div>
                    </div>
                </div>
                <div class="module-analyse module-top" v-if="dataAnalyseList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">数据分析</div>
                    </div>
                    <div class="module-content">
                        <div class="analyse-item" v-for="item in dataAnalyseList">
                            <img src="./../../../assets/img/image/analyseCover.png" alt="">
                            <span @click="toAnalyseDetail(item.id)" class="text-overflow">{{item.content.title}}</span>
                        </div>
                    </div>
                </div>
                <div class="module-download module-top" v-if="localList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">本地下载</div>
                    </div>
                    <div class="module-content">
                        <el-checkbox-group v-model="checkList" @change="handleCheckedCitiesChange">
                            <div class="download-item" v-for="item in localList">
                                <div class="download-item-left">
                                    <el-checkbox style="margin-right: 20px" :label="item.id" :key="item.id"></el-checkbox>
                                    <div class="img-box">
                                        <!--                        <img src="../../../assets/img/image/zonghe-tupian.png" alt="">-->
                                        <img :src="downloadTypeImg(item.file_name)" alt="">
                                    </div>
                                    <span :title="item.file_name">{{item.file_name}}</span>
                                </div>
                                <div class="download-item-right" @click="downloadAnnex(item)">
                                    <i class="iconfont">&#xe67d;</i>
                                    <span>下载</span>
                                </div>
                            </div>
                        </el-checkbox-group>
                        <div class="download-all-btn">
                            <el-button class="blue-btn" @click="downloadAllClick"><i class="iconfont">&#xe67d;</i><span>下载</span></el-button>
                        </div>
                    </div>
                </div>
                <div class="module-link module-top" v-if="linkList.length">
                    <div class="module-title">
                        <div class="module-title-line"></div>
                        <div class="module-title-name">链接</div>
                    </div>
                    <div class="module-content">
                        <div class="link-item" v-for="(item,index) in linkList">
                            <span>链接{{index + 1}}：</span>
                            <a class="text-overflow" :href="item.content.url" target="_blank">{{item.content.url}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-mid">
                <div class="content-details">
                    <span>教师评语</span>
                    <span v-if="teacherRemark">{{teacherRemark}}</span>
                    <span v-else>暂无评语</span>
                </div>
                <div class="content-mid-mid">
                    <span>服务打分</span>
                    <el-rate disabled v-if="teacherScore" v-model="teacherScore"></el-rate>
                    <span v-else>暂未评分</span>
                </div>
            </div>
        </div>
        <!--附件详情弹窗-->
        <el-dialog :visible.sync="dialogViewAnnex" width="66%" class="annex-list-dialog" :close-on-click-modal="false">
            <div class="view-script-main" :native="false" v-if="currentClass === 1">
                <div class="view-script-item-top">
                    <div class="view-script-img">
                        <img :src="scriptDetail.cover_url" alt="">
                    </div>
                    <div class="view-script-right">
                        <div class="view-script-name"><span>脚本名称：{{scriptDetail.name}}</span></div>
                        <div class="view-script-fenlei"><span>脚本分类：{{scriptDetail.classification_name}}</span></div>
                        <div class="view-script-jianjie"><span>脚本简介：{{scriptDetail.script_introduction}}</span></div>
                    </div>
                </div>
                <div class="view-script-body" v-if="scriptDetail.script_content!=='<p><br></p>' && scriptDetail.script_content !== null">
                    <div class="view-script-body-title">脚本内容</div>
                    <div class="view-script-body-content" v-html="scriptDetail.script_content"></div>
                </div>
                <el-scrollbar class="script-dialog-table">
                    <div class="dialog-table-row">
                        <div class="dialog-table-column">镜头</div>
                        <div class="dialog-table-column">景别</div>
                        <div class="dialog-table-column">拍摄方向</div>
                        <div class="dialog-table-column">运镜</div>
                        <div class="dialog-table-column">拍摄内容</div>
                        <div class="dialog-table-column">音乐/字幕</div>
                        <div class="dialog-table-column">时间</div>
                    </div>
                    <div class="dialog-table-row" v-for="item in scriptDetail.camera_lens">
                        <div class="dialog-table-column"><span class="text-overflow">{{item.number}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.commentary}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.shot_method}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.carrying_mirror}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.frame}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.music}}</span></div>
                        <div class="dialog-table-column"><span class="text-overflow">{{item.time}}</span></div>
                    </div>
                </el-scrollbar>
            </div>
            <span slot="footer" class="dialog-footer">
                    <el-button type="primary" class="determine-btn" @click="dialogViewAnnex = false">确 定</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "AnnexDetails",
        computed: {
            ...mapGetters(['libraries','localAnnexLists','teacherRemarkObj', 'getStudentContent'])
        },
        created() {
            this.getAnnexList();
        },
        mounted() {
            console.log('this.$route.query.tState',this.$route.query.tState)
            if(localStorage.getItem('currentClass')){
                this.currentClass = 6;
                localStorage.removeItem('currentClass')
            }
            if(this.teacherRemarkObj){
                this.teacherRemark = this.teacherRemarkObj.comment;
                this.teacherScore = this.teacherRemarkObj.score;
                this.teacherRemarkId = this.teacherRemarkObj.id;
            }
            this.studentContent = this.getStudentContent;
        },
        data() {
            return {
                student_name: this.$route.query.sName || '',
                class_name: this.$route.query.cName || '',
                task_works_state: this.$route.query.tState || '',
                //附件分类
                annexClass: [
                    {
                        id: 1,
                        name: '脚本'
                    },
                    {
                        id: 2,
                        name: '图文'
                    },
                    {
                        id: 3,
                        name: '视频'
                    },
                    {
                        id: 6,
                        name: '数据分析'
                    },
                    {
                        id: 4,
                        name: '本地文件'
                    },
                    {
                        id: 5,
                        name: '链接'
                    },
                ],
                //选择的附件分类
                currentClass: 1,
                //脚本列表
                scriptList: [],
                //脚本详情
                scriptDetail: {
                    cover_url: '',
                    name: '',
                    classification_name: '',
                    script_introduction: '',
                    script_content: '',
                    camera_lens: []
                },
                //图文列表
                graphicList: [],
                //视频列表
                videoList: [],
                //本地文件列表
                localList: [],
                //链接列表
                linkList: [],
                // 数据分析列表
                dataAnalyseList:[],
                //附件详情标题
                annexDetailTitle: '',
                dialogViewAnnex: false,
                checkLocalFile: [],
                checkLocalFiles: [],
                taskType:this.$route.query.taskType || '', //任务类型
                teacherRemark:'',
                teacherScore:void 0,
                teacherRemarkId:'',
                taskId:this.$route.query.taskId || '',
                userId:this.$route.query.userId || '',
                studentContent:'',
                checkList:[]
            }
        },
        methods: {
            //返回
            goBack() {
                this.$router.go(-1);
            },
            //选择附件分类
            chooseAnnexClass(item) {
                this.currentClass = item.id;
                // if (item.id === 4) {
                //     this.localList = []
                // }

            },
            //获取附件列表
            getAnnexList() {
                this.libraries.forEach((item) => {
                    if (item.type === 1) {
                        this.scriptList.push(item);
                    }
                    if (item.type === 2) {
                        this.graphicList.push(item);
                    }
                    if (item.type === 3) {
                        this.videoList.push(item);
                    }
                    if (item.type === 4) {
                        this.linkList.push(item);
                    }
                    if (item.type === 5){
                        this.dataAnalyseList.push(item)
                    }
                });
                if (this.localAnnexLists.length > 0) {
                    this.localList = this.localAnnexLists;
                    let leng = this.localList.length;
                    // 添加id标识多选下载
                    for(let i = 0; i < leng; i++){
                        this.$set(this.localList[i], 'id', i+1)
                    }
                }
            },
            //查看脚本详情
            viewScriptDetail(item) {
                this.dialogViewAnnex = true;
                this.annexDetailTitle = '查看脚本';
                this.scriptDetail.cover_url = item.content.cover_url;
                this.scriptDetail.name = item.content.name;
                this.scriptDetail.classification_name = item.content.classification_name;
                this.scriptDetail.script_introduction = item.content.script_introduction;
                this.scriptDetail.script_content = item.content.script_content;
                this.scriptDetail.camera_lens = JSON.parse(item.content.camera_lens);
            },
            //查看图文详情
            viewGraphicDetail(item) {
                window.location.href = item.url;
            },
            //下载附件
            downloadAnnex(item) {
                // window.location.href = item.file_path;
                let src = '/' + item.file_path.split('/').slice(3).join('/');
                let x = new XMLHttpRequest();
                x.open("GET", src, true);
                x.responseType = 'blob';
                // x.onprogress = function (e){
                // }
                x.onload = function (e) {
                    let url = window.URL.createObjectURL(x.response)
                    let a = document.createElement('a');
                    a.href = url
                    a.download = item.file_name
                    a.click()
                }
                x.send();
            },
            //下载选中
            downloadAllClick(){
                if(this.checkList.length){
                    this.checkList.forEach(item=>{
                        this.localList.forEach(item2=>{
                            if(item === item2.id){
                                this.downloadAnnex(item2);
                            }
                        })
                    })
                } else {
                    this.$message.warning('请选择所要下载的文件')
                }
            },
            chooseLocalFile(item) {
                // window.location.href = item.file_path;
                // console.log('item',item);
                this.checkLocalFiles.push(item);
            },
            chooseLocalFiles() {
                this.checkLocalFiles.forEach((item) => {
                    window.location.href = item.file_path;
                });
            },
            //查看数据分析详情
            toAnalyseDetail(id){
                this.$router.push({
                    path:'/student/task/analysedetail',
                    query:{
                        id:id
                    }
                })
            },
            handleCheckedCitiesChange(val){

            },
            //下载文件类型图片
            downloadTypeImg(name){
                let src = '';
                let typeName = name.substring(name.lastIndexOf(".")+1,name.length);
                switch (typeName){
                    case 'rar':
                    case 'zip':
                    case '7z':
                        src = require('../../../assets/img/image/wenjianjia-tupian.png');
                        break;
                    case 'txt':
                    case 'doc':
                    case 'xls':
                    case 'ppt':
                    case 'docx':
                    case 'xlsx':
                    case 'pptx':
                        src = require('../../../assets/img/image/wenben-tupian.png');
                        break;
                    case 'mp4':
                    case 'flv':
                    case 'rmvb':
                    case 'mvb':
                        src = require('../../../assets/img/image/shipin-tupian.png');
                        break;
                    case 'jpg':
                    case 'png':
                    case 'pdf':
                    case 'tiff':
                    case 'swf':
                    case 'gif':
                        src = require('../../../assets/img/image/img-tupian.png');
                        break;
                    default:
                        src = require('../../../assets/img/image/moren-tupian.png')
                }
                return src;
            }
        }
    }
</script>

<style scoped lang="scss">
    .interactive-manage {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 100px);
        .interactive-content {
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;
            .content-top {
                display: flex;
                align-items: center;
                width: 100%;
                height: 68px;
                background-color: #fff;
                margin-bottom:20px;
                .student-name {
                    color: #333;
                    font-size: 24px;
                    margin: 0 50px 0 40px;
                    font-weight: 500;
                }
                .class-name {
                    color: #333;
                    font-size: 24px;
                    font-weight: 500;
                }
                .task-works_-tate {
                    color: #333;
                    font-size: 24px;
                    font-weight: 500;
                    margin: 0 50px 0 40px;
                }
            }
            .content-details{
                display: flex;
                flex-direction: column;
                background: #fff;
                padding: 20px 40px;
                span{
                    color: #333;
                    &:nth-child(1){
                        font-size: 18px;
                        line-height: 1;
                    }
                    &:nth-child(2){
                        font-size: 16px;
                        display: inline-block;
                        width: 100%;
                        padding: 16px 20px;
                        background: #F8F7FF;
                        border-radius: 4px;
                        line-height: 24px;
                        margin-top: 20px;
                    }
                }
            }
            .content-module{
                display: flex;
                flex-direction: column;
                .module-title{
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    .module-title-line{
                        width: 6px;
                        height: 10px;
                        background: #574DED;
                        border-radius: 3px;
                        margin-right: 10px;
                    }
                    .module-title-name{
                        color: #333333;
                        font-size: 18px;
                    }
                }
                .module-content{
                    margin-top: 10px;
                    background: #fff;
                    display: flex;
                }
                .module-top{
                    margin-top: 20px;
                }
                .module-script{
                    .module-content{
                        padding: 30px 46px 40px;
                        flex-wrap: wrap;
                        justify-content: space-around;
                        .script-item{
                            width:358px;
                            margin-top: 10px;
                            &:hover {
                                cursor: pointer;
                            }
                            .item-cover {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    width: 100%;
                                }
                            }
                            .annex-info {
                                border: 1px solid #DDDDDD;
                                height: 80px;
                                span {
                                    display: block;
                                    height: 40px;
                                    line-height: 40px;
                                    width: 100%;
                                    padding: 0 12px;
                                    box-sizing: border-box;
                                }
                                .title {
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space: nowrap;
                                    color: #333;
                                    font-size: 16px;
                                }
                                .bottom {
                                    display: flex;
                                    justify-content: space-between;
                                    color: #666666;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
                .module-teletext{
                    .module-content{
                        flex-wrap: wrap;
                        padding: 0 76px 0 90px;
                        .teletext-item{
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            width: 150px;
                            margin: 40px 60px 40px 0;
                            .item-cover {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                width: 100px;
                                height: 100px;
                                img {
                                    max-height: 100%;
                                    max-width: 100%;
                                }
                            }
                            .title {
                                margin-top: 20px;
                                line-height: 1;
                                display: block;
                                width: 100%;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                                color: #444444;
                                font-size: 14px;
                            }
                            &:hover {
                                .title {
                                    cursor: pointer;
                                    color: #66b1ff;
                                }
                            }
                        }
                    }
                }
                .module-video{
                    .module-content{
                        padding: 70px 0;
                        flex-direction: column;
                        align-items: center;
                        .video-item{
                            width: 970px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            margin-top: 60px;
                            &:nth-child(1){
                                margin-top: 0;
                            }
                            .item-cover {
                                width: 100%;
                                line-height: 1;
                                video{
                                    width: 100%;
                                }
                            }
                            .title {
                                margin-top: 26px;
                                color: #333333;
                                font-size: 24px;
                                line-height: 1;
                            }
                        }
                    }
                }
                .module-analyse{
                    .module-content{
                        flex-wrap: wrap;
                        padding: 0px 0 66px;
                        .analyse-item{
                            margin-top: 60px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: calc(100% / 7);
                            text-align: center;
                            img{
                                max-width: 66px;
                                max-height: 76px;
                            }
                            span{
                                line-height: 1;
                                margin-top: 24px;
                                width: 90%;
                                font-size: 16px;
                                color: #333333;
                                cursor: pointer;
                            }
                        }
                    }
                }
                .module-download{
                    .module-content{
                        flex-direction: column;
                        padding: 54px 0 40px;
                        .download-item{
                            display: flex;
                            justify-content: space-between;
                            height: 50px;
                            background: #F8F7FF;
                            margin-top: 10px;
                            padding: 0 190px 0 130px;
                            .download-item-left{
                                display: flex;
                                align-items: center;
                                .img-box{
                                    width: 30px;
                                    height: 35px;
                                    img{
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                                span{
                                    margin-left: 16px;
                                    color: #333333;
                                    font-size: 16px;
                                }
                            }
                            .download-item-right{
                                display: flex;
                                align-items: center;
                                cursor: pointer;
                                span{
                                    color: #333333;
                                    font-size: 14px;
                                }

                            }
                        }
                        .download-all-btn{
                            display: flex;
                            justify-content: flex-end;
                            .el-button{
                                margin: 50px 40px 0 0;
                                padding: 10px 20px;
                                span{
                                    font-size: 16px;
                                    margin-left: 8px;
                                }
                            }
                        }
                    }
                    ::v-deep .el-checkbox{
                        .el-checkbox__label{
                            display: none;
                        }
                        &.is-checked{
                            .is-checked{
                                .el-checkbox__inner{
                                    background-color: #9ACC5B;
                                    border-color: #9ACC5B;
                                }
                            }
                        }
                    }
                }
                .module-link{
                    .module-content{
                        display: flex;
                        flex-direction: column;
                        padding: 54px 0 128px;
                        .link-item{
                            padding: 0 40px 0 348px;
                            line-height: 1;
                            height: 50px;
                            display: flex;
                            align-items: center;
                            background: #F8F7FF;
                            margin-top: 10px;
                            span{
                                display: block;
                                font-size: 16px;
                                color: #333;
                            }
                            a{
                                display: block;
                                font-size: 16px;
                                flex: 1;
                                width: 1%;
                                color: #574DED;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .content-mid{
                margin:20px 0;
                display: flex;
                flex-direction: column;
                background: #fff;
                padding: 20px 30px 30px 40px;
                .content-mid-top, .content-mid-mid{
                    display: flex;
                    &.content-mid-mid{
                        margin-top: 30px;
                        .teacher-score{
                            span{
                                margin-left: 10px;
                            }
                        }
                    }
                    span{
                        margin-right: 20px;
                        color: #333;
                        font-size: 18px;
                    }
                }
                .content-details {
                    padding: 20px 0;
                    span {
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .blue-btn {
            padding: 10px 36px;
            margin-bottom: 20px;
        }
    }

    ::v-deep.annex-list-dialog {
        border-radius: 6px;
        .el-dialog__header{
            display: none;
        }
        ::v-deep .el-dialog__body {
            padding: 30px;
        }
    }

    .view-script-main{
        display: flex;
        flex-direction: column;
        height: 100%;
        //::v-deep .el-scrollbar__wrap {
        //  overflow-x: hidden;
        //}
        .view-script-item-top{
            display: flex;
            //align-items: center;
            .view-script-img{
                width:390px;
                height:224px;
                margin-right:30px;
                img{
                    max-width:390px;
                    max-height:224px;
                    border-radius: 10px;
                }
            }
            .view-script-right{
                flex: 1;
                width: 1%;
                .view-script-name{
                    margin-top: 10px;
                    font-size: 18px;
                    color: #333;
                }
                .view-script-fenlei{
                    font-size: 16px;
                    color: #666;
                    margin-top: 30px;
                }
                .view-script-jianjie{
                    font-size: 16px;
                    color: #666;
                    margin-top: 8px;
                    line-height: 30px;
                }
            }
        }
        .view-script-body{
            display: flex;
            flex-direction: column;
            border: 2px solid #584EEE;
            //box-shadow: 0px 10px 0px 0px #ECEBFF;
            border-radius:20px;
            margin:48px 0 30px;
            .view-script-body-title{
                width: 160px;
                height: 32px;
                background: #584EEE;
                border-radius: 16px;
                line-height: 32px;
                text-align: center;
                color: #fff;
                margin: -12px 0 0 24px;
                position: relative;
                &:before{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    left: 20px
                }
                &:after{
                    content: "";
                    background: #fff;
                    -webkit-border-radius: 50%;
                    border-radius: 50%;
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    top: 12px;
                    right: 20px
                }
            }
            .view-script-body-content{
                display: flex;
                min-height: 100px;
                padding-left: 22px;
            }
        }

        ::v-deep.el-table{
            .warning-row {
                background: #ECEBFF;
            }
        }
    }
    ::v-deep .el-dialog__footer {
        text-align: center;
        padding: 10px 20px;
        .dialog-footer {
            .el-button {
                padding: 10px 36px;
                margin-bottom: 20px;
            }
            .el-button--primary {
                background-color: #2338E6;
                border: 1px solid #2338E6;
                &:hover {
                    background-color: #1b2dbf;
                    border: 1px solid #1b2dbf;
                }
            }
        }
    }
    .graphic-list {
        display: flex;
        flex-wrap: wrap;
        .graphic-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 200px;
            height: 130px;
            margin: 0 20px 20px 0;
            .item-cover {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100px;
                height: 100px;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .title {
                display: block;
                width: 100%;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                color: #444444;
                font-size: 14px;
            }
            &:hover {
                .title {
                    cursor: pointer;
                    color: #66b1ff;
                }
            }
        }
    }
    .video-list {
        display: flex;
        flex-wrap: wrap;
        .video-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc((100% - 40px)/3);
            height: 280px;
            margin: 0 20px 20px 0;
            &:nth-child(3n) {
                margin-right: 0;
            }
            .item-cover {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 250px;
                video {
                    height: 100%;
                    width: 100%;
                }
            }
            .title {
                display: block;
                width: 100%;
                height: 30px;
                line-height: 30px;
                overflow: hidden;
                text-overflow:ellipsis;
                white-space: nowrap;
                color: #333;
                font-size: 16px;
            }
        }
    }
    .link-list {
        .link-item {
            width: 100%;
            height: 50px;
            background-color: #F8F7FF;
            line-height: 50px;
            /*text-align: center;*/
            margin-bottom: 10px;
            display: flex;
            padding-left: 20px;
            .annex-name {
                margin-right: 20px;
            }
            .clip-checkbox {
                height: 100%;
                .img-item {
                    width: 100%;
                    text-align: left;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    ::v-deep .el-checkbox__label {
                        flex: 1;
                        width: 1%;
                        display: flex;
                    }
                }
            }
        }
    }
    .download-btn {
        float: right;
        padding: 8px 34px;
        background-color: #2338E6;
        border: 1px solid #2338E6;
        &:hover {
            background-color: #1b2dbf;
            border: 1px solid #1b2dbf;
        }
    }
    .script-dialog-table{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        height: 265px;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        .dialog-table-row{
            display: flex;
            &:nth-child(odd){
                background: #DEDCFD;
            }
            &:nth-child(even){
                background: #F8F7FF;
            }
            &:nth-child(1){
                .dialog-table-column{
                    font-size: 14px;
                    color: #222222;
                    font-weight: 400;
                }
            }
            .dialog-table-column{
                display: flex;
                flex: 1;
                width: 1%;
                justify-content: center;
                align-items: center;
                height: 50px;
            }
        }
    }
    .download{
        cursor: pointer;
        &:hover{
            color: #66b1ff;
        }
    }
    .is-qualified, .no-qualified{
        font-size: 38px;
        line-height: 1;
        margin: 0 50px 0 40px;
        &.is-qualified{
            color: #20C997;
        }
        &.no-qualified{
            color: #FF0000;
        }
    }
</style>